<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
      <table>
        <tr>
          <th>Branch Name</th>
          <th>Branch Code</th>

          <th>Action</th>
        </tr>
        <tr v-for="(data,i) in unit" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter" :maxlenth="max"
                v-model="data.name"
                :disabled="!data.is_edit_mode"
              />
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input
                type="text"
                placeholder="Enter" :maxlenth="max"
                v-model="data.branch_id"
                :disabled="!data.is_edit_mode"
              />
            </div>
          </td>
          <td class>

            <a class="edit_icon" title="EDIT" @click="unit_id = data.id,is_editable=true"></a>
           
             <!-- <a class="delete_icon" title="DELETE" @click="deleteUnit(data.id)">DELETE</a> -->

             <a v-if="data.user.length != 0 " class="delete_icon" title="This Branch can't be deleted as it is linked with a User"></a>
              <a v-if="data.user.length == 0 " class="delete_icon" title="DELETE" @click="deleteUnit(data.id)"></a>



            <a class="view_icon" title="VIEW" @click="unit_id = data.id, is_editable=false"></a>

          </td>
        </tr>
      </table>
    </div>

    <div class="black-overlay" v-if="unit_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <BranchPopup v-if="unit_id" :unit_id="unit_id" @closeModal="fetchUnit" :is_editable="is_editable" />
        </div>
      </div>
    </div>


  </div>
</template>
<script>

import BranchPopup from "./BranchEdit_Popup.vue";

export default {
  name: "Unit",
  components: {
    BranchPopup
  },
  data() {
    return {
      max:30,
      unit: [],
      unit_id : ""
    };
  },
  created() {
    this.fetchUnit();
  },
  methods: {
    fetchUnit() {
      this.unit_id = '';
      this.axios
        .get("/api/branch/view")
        .then(response => {
          console.log(response);
          this.unit = response.data.branches.map(element => {
            element.is_edit_mode = false;
            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteUnit(id) {
      if (confirm("Are you sure you want to delete this branch?")) {
        this.axios
          .delete("/api/branch/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchUnit();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    cancelUnit() {
      this.fetchUnit();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}
.scoped_cover_div section {
  padding: 0;
}
.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}
.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 33.33%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}
.righted {
  text-align: right;
  width: auto;
}
.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}
.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}
.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}
.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}
.edit_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_edit.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.delete_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_delete.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
.view_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../../../assets/ico_view.png) no-repeat;
  background-size: cover;
  margin: 5px 10px;
}
</style>